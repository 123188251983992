export const useModalStore = defineStore('modalStore', () => {
  const showFundModal = ref(false)
  const showWithdrawModal = ref(false)

  function openFundModal(payload: boolean) {
    showFundModal.value = payload
  }
  function openWithdrawModal(payload: boolean) {
    showWithdrawModal.value = payload
  }

  return {
    showFundModal,
    showWithdrawModal,
    openFundModal,
    openWithdrawModal,
  }
})
